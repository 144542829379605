.repository {
    text-align: left;
    padding-bottom: 6px;

    .label-text {
        font-size: 12px;
        padding-bottom: 3px;
        padding-left: 3px;
        margin-bottom: 0;
    }

    .text-background {
        background-color: $color-lightgrey;
        padding: 18px 54px 18px 34px;
        display: block;
    }
}

.issues-container {
    display: flex;
    justify-content: space-between;
    width: 70vw;
    max-width: 1100px;
    align-items: center;
    margin-top: 30px;


    
    #dropdown-custom {
        background-color: white;
        color: black;
        border: none;
        border-radius: 0;
    }
    .dropdown-menu.show {
        width:300px;
        margin-top: 10px;
        height: 250px;
        overflow: auto;
        -webkit-box-shadow: inset 0px -55px 42px -62px rgba(209,209,209,1);
        -moz-box-shadow: inset 0px -55px 42px -62px rgba(209,209,209,1);
        box-shadow: inset 0px -55px 42px -62px rgba(209,209,209,1);

        .dropdown-item {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid $color-lightgrey;
            padding: 0.7em 1em;
            cursor: default;
        }
        .label-week {
            color: $color-modern-blue;
            font-weight: $body-bold-weight;
        }
        .dropdown-item p {
            margin-bottom: 2px;
        }
        .dropdown-item span {
            font-weight: $info-weight;
            font-size: 14px;
            color: gray;
        }
    }
}

