@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);

$font-family-base: "Poppins", Helvetica, Arial, sans-serif;


$small-font: 12px;

$header-font: 24px;

$header-weight: 700;
$subheader-weight: 600;
$body-bold-weight: 500;
$body-weight: 400;
$info-weight: 300;