@import '../variables/other';
@import '../variables/color';
@import '../fonts/fonts';


.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 80px;

  .spinner {
    color: $color-modern-blue;
  }
}
