@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap);
.blue {
  color: #4525F2;
}

:root {
  font-family: "Poppins", Helvetica, Arial, sans-serif;
}

body {
  font-family: "Poppins", Helvetica, Arial, sans-serif;
}

.text-right {
  text-align: right;
}

.blue {
  color: #4525F2;
}

.error {
  margin: 40px;
  padding: 20px;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: rgba(255, 0, 0, 0.2);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.error h4 {
  font-size: 14px;
  margin: 0;
}

.error:hover {
  background: #F00;
  color: #FFF;
}

.blue {
  color: #4525F2;
}

.App .App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App .App-header {
  border-top: solid 10px #4525F2;
  min-height: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  color: black;
  padding: 0 2rem;
}

.App .title-nav {
  margin-top: 30px;
}

.App .App-link {
  color: #61dafb;
}

h1 {
  font-size: 24px;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 30px;
}

h2 {
  font-size: 24px;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 30px;
  padding-bottom: 4px;
}

.borderBottom {
  border-bottom: 3px solid #4525F2;
}

.borderBottomWhite {
  border-bottom: 3px solid #fff;
}

#main {
  height: 80vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

th, td {
  text-align: left;
  vertical-align: middle;
}

input::-webkit-datetime-edit {
  overflow-x: hidden;
  overflow-y: hidden;
  display: -webkit-box;
  display: flex;
}

.col-md-0-5 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 4.1666665%;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
}

.flex-container {
  width: 86%;
}

.search-trainee {
  -webkit-transition: right 2s ease-in-out;
  transition: right 2s ease-in-out;
  -webkit-transition: right 1s ease-in-out;
  -moz-transition: right 1s ease-in-out;
  -o-transition: right 1s ease-in-out;
}

.collapsee {
  -webkit-transition: display 0s, opacity 0.3s ease-in-out;
  transition: display 0s, opacity 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  display: none;
}

.collapsee.active {
  opacity: 1;
  visibility: visible;
  display: block;
  -webkit-animation: fade 1s;
          animation: fade 1s;
}

@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.my-node-enter {
  opacity: 0;
}

.my-node-enter-active {
  opacity: 1;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
}

.my-node-exit {
  opacity: 1;
}

.my-node-exit-active {
  opacity: 0;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
}

#popup {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.repository {
  text-align: left;
  padding-bottom: 6px;
}

.repository .label-text {
  font-size: 12px;
  padding-bottom: 3px;
  padding-left: 3px;
  margin-bottom: 0;
}

.repository .text-background {
  background-color: #EBEBEB;
  padding: 18px 54px 18px 34px;
  display: block;
}

.issues-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 70vw;
  max-width: 1100px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 30px;
}

.issues-container #dropdown-custom {
  background-color: white;
  color: black;
  border: none;
  border-radius: 0;
}

.issues-container .dropdown-menu.show {
  width: 300px;
  margin-top: 10px;
  height: 250px;
  overflow: auto;
  -webkit-box-shadow: inset 0px -55px 42px -62px #d1d1d1;
  box-shadow: inset 0px -55px 42px -62px #d1d1d1;
}

.issues-container .dropdown-menu.show .dropdown-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border-bottom: 1px solid #EBEBEB;
  padding: 0.7em 1em;
  cursor: default;
}

.issues-container .dropdown-menu.show .label-week {
  color: #4525F2;
  font-weight: 500;
}

.issues-container .dropdown-menu.show .dropdown-item p {
  margin-bottom: 2px;
}

.issues-container .dropdown-menu.show .dropdown-item span {
  font-weight: 300;
  font-size: 14px;
  color: gray;
}

.blue {
  color: #4525F2;
}

.spinner-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 80px;
}

.spinner-container .spinner {
  color: #4525F2;
}

.blue {
  color: #4525F2;
}

form .datepicker {
  padding: 0.75rem !important;
}

.modal-body {
  padding: 3rem;
}

.modal-body form {
  font-family: "Poppins", Helvetica, Arial, sans-serif;
}

.modal-body form input::-webkit-input-placeholder {
  font-size: 12px;
}

.modal-body form input:-ms-input-placeholder {
  font-size: 12px;
}

.modal-body form input::-ms-input-placeholder {
  font-size: 12px;
}

.modal-body form input::placeholder {
  font-size: 12px;
}

.modal-body form div:last-child {
  margin-top: 26px;
}

.form-control {
  padding: 0.75rem;
}

.blue {
  color: #4525F2;
}

.git-card {
  position: relative;
  padding: 8px 0;
  background: #ebebeb;
  border-radius: .4em;
  -webkit-box-shadow: 0px 9px 24px -3px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 9px 24px -3px rgba(0, 0, 0, 0.18);
  margin-top: 30px;
}

.git-card .git-card-img {
  height: 50px;
  border-radius: 50px;
  margin: 12px;
}

.git-card .text-muted {
  margin-bottom: 0;
}

.git-card .git-card-text {
  text-align: left;
}

.git-card:after {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 14px solid transparent;
  border-bottom-color: #ebebeb;
  border-top: 0;
  margin-left: -14px;
  margin-top: -14px;
}

.blue {
  color: #4525F2;
}

.btn-primary {
  background-color: #4525F2;
  border-radius: 26px;
  padding: 0.8em 1em;
  font-weight: 500;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-transfer {
  width: 100%;
  font-size: 17px;
}

.icon-button {
  padding: 0.75rem 1rem;
}

.zoek-button {
  padding: 0.8rem;
}

.btn-dark:hover {
  background-color: #000;
}

.navigation-buttons {
  margin-bottom: 60px;
}

.navigation-buttons .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.navigation-buttons .btn-outline-primary {
  color: black;
  border-color: black;
  padding: .65rem 1.7rem;
}

.navigation-buttons .btn-outline-primary:hover {
  color: white;
  border-color: black;
  background-color: black;
}

.blue {
  color: #4525F2;
}

.container-succes {
  margin: 0 auto;
  width: 30%;
  text-align: center;
  margin-top: 10%;
}

.success-container {
  width: 34vw;
  border: 2px solid #4525F2;
  border-radius: 10px;
  padding: 30px 20px 30px 50px;
  text-align: left;
  margin-bottom: 30px;
}

.success-container h2 {
  margin-bottom: 0;
}

.success-container .card-img {
  height: 80px;
  width: 80px;
  border-radius: 50px;
}

.success-container .card-username {
  text-align: left;
}

.success-container .repo-info {
  margin-top: 30px;
}

.success-container .repo-info p {
  color: #4525F2;
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 300;
}

.success-container .repo-info h6 {
  font-size: 15px;
  font-weight: 400;
}

.blue {
  color: #4525F2;
}

.radio-wrapper {
  max-height: 280px;
  overflow: auto;
  overflow-y: scroll !important;
  -webkit-box-shadow: inset 0px -55px 42px -62px #d1d1d1;
  box-shadow: inset 0px -55px 42px -62px #d1d1d1;
}

.radio-wrapper .list-group-item {
  padding: 1rem 1.2rem;
  background-color: rgba(255, 255, 255, 0);
  min-height: 110%;
}

.radio-wrapper .list-group-item .form-check-label {
  font-weight: 600;
  padding-left: 4px;
}

.radio-wrapper .list-group-item p {
  padding-left: 28px;
  margin-bottom: 0;
}

.blue {
  color: #4525F2;
}

#popup {
  background-color: #4525F2;
  height: 280px;
  padding: 4rem 6rem;
}

#popup .col-btn {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
}

#popup .col-btn button {
  margin-top: 8px;
}

#popup h4 {
  color: #fff;
}

#popup .form-label {
  color: #fff;
}

#popup .text-url, #popup a {
  color: rgba(255, 255, 255, 0.639) !important;
}

#popup input::-webkit-input-placeholder {
  color: lightgray !important;
}

#popup input:-ms-input-placeholder {
  color: lightgray !important;
}

#popup input::-ms-input-placeholder {
  color: lightgray !important;
}

#popup input::placeholder {
  color: lightgray !important;
}
