@import './fonts';

:root {
    font-family: $font-family-base;
}

body {
    font-family: $font-family-base;
}

.text-right {
    text-align: right;
}