@import '../fonts/fonts';
@import '../variables/color';

#popup {
    background-color: $color-modern-blue;
    height: 280px;
    padding: 4rem 6rem;

    .col-btn {
        align-self: center;
        button{
            margin-top: 8px;
        }
    }

    h4 {
        color: #fff;
    }

    .form-label {
        color: #fff;
    }

    .text-url, a {
        color: rgba(255, 255, 255, 0.639) !important;
    }

    input::placeholder {
        color: lightgray !important
    }
}