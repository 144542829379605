@import '../variables/other';
@import '../variables/color';
@import '../fonts/fonts';

.radio-wrapper {

    max-height: 280px;
    overflow: auto;
    overflow-y: scroll !important;
    -webkit-box-shadow: inset 0px -55px 42px -62px rgba(209,209,209,1);
    -moz-box-shadow: inset 0px -55px 42px -62px rgba(209,209,209,1);
    box-shadow: inset 0px -55px 42px -62px rgba(209,209,209,1);

    .list-group-item {
        padding: 1rem 1.2rem;
        background-color: rgba(255, 255, 255, 0) ;
        min-height: 110%;

        .form-check-label {
            font-weight: $subheader-weight;
            padding-left: 4px;
        }
        
        p {
            padding-left: 28px;
            margin-bottom: 0;
        }
    }
  }