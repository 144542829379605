@import '../variables/other';
@import '../variables/color';
@import '../fonts/fonts';

.git-card {
  position: relative;
  padding: 8px 0 ;
	background: #ebebeb;
	border-radius: .4em;
  -webkit-box-shadow: 0px 9px 24px -3px rgba(0,0,0,0.18);
  -moz-box-shadow: 0px 9px 24px -3px rgba(0,0,0,0.18);
  box-shadow: 0px 9px 24px -3px rgba(0,0,0,0.18);
  margin-top: 30px;

  .git-card-img{
    height:50px;
    border-radius:50px;
    margin: 12px;
  }
  .text-muted{
    margin-bottom: 0;
  }
  .git-card-text {
    text-align: left;
  }
}

.git-card:after {
	content: '';
	position: absolute;
	top: 0;
	left: 50%;
	width: 0;
	height: 0;
	border: 14px solid transparent;
	border-bottom-color: #ebebeb;
	border-top: 0;
	margin-left: -14px;
	margin-top: -14px;
}