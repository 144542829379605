@import '../variables/other';
@import '../variables/color';
@import '../fonts/fonts';

.error {
  margin: $box-padding * 4;
  padding: $box-padding * 2;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 0, 0, 0.2);
  transition: all 0.3s ease;

  h4 {
    font-size: 14px;
    margin: 0;
  }

  &:hover {
    background: #F00;
    color: #FFF;
  }
}