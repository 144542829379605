$color-black: rgba(0, 0, 0, 1);
$color-black-transparent: rgba(0, 0, 0, 0.5);
$color-black-transparent-darker: rgba(0, 0, 0, 0.6);
$color-white-red: rgb(233, 224, 222);
$color-lightblue: #61dafb;
$color-modern-blue: #4525F2;

$color-lightgrey: #EBEBEB;

.blue {
    color: $color-modern-blue;
}