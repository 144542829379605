@import '../variables/other';
@import '../variables/color';
@import '../fonts/fonts';

.App {

  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }

  .App-header {
    border-top: solid 10px $color-modern-blue;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $color-black;
    padding: 0 2rem;
  }

  .title-nav {
    margin-top: 30px;
  }

  .App-link {
    color: $color-lightblue;
  }
}

h1 {
  font-size: $header-font;
  font-weight: $header-weight;
  display: inline-block;
  margin-bottom: 30px;
}

h2 {
  font-size: $header-font;
  font-weight: $subheader-weight;
  display: inline-block;
  margin-bottom: 30px;
  padding-bottom: 4px;
}

.borderBottom {
  border-bottom: 3px solid $color-modern-blue;
}

.borderBottomWhite {
  border-bottom: 3px solid #fff;
}

#main {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

th, td {
  text-align: left;
  vertical-align: middle;
}


input::-webkit-datetime-edit {
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
}

.col-md-0-5 {
  flex: 0 0 auto;
  width: 4.1666665%;
  align-self: center;
}

.flex-container {
  width: 86%;
}

.search-trainee {
  transition: right 2s ease-in-out;
  -webkit-transition: right 1s ease-in-out;
  -moz-transition: right 1s ease-in-out;
  -o-transition: right 1s ease-in-out;
}

.collapsee {
  -webkit-transition: display 0s, opacity 0.3s ease-in-out;
  -moz-transition: display 0s, opacity 0.3s ease-in-out;
  -ms-transition: display 0s, opacity 0.3s ease-in-out;
  -o-transition: display 0s, opacity 0.3s ease-in-out;
  transition: display 0s, opacity 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  display: none;
}

.collapsee.active {
  opacity: 1;
  visibility: visible;
  display: block;
  animation: fade 1s;
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.my-node-enter {
  opacity: 0;
}

.my-node-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.my-node-exit {
  opacity: 1;
}

.my-node-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

#popup {
  transition: all 0.3s ease;
}