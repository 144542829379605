@import '../variables/other';
@import '../variables/color';
@import '../fonts/fonts';


.container-succes {
    margin: 0 auto;
    width: 30%;
    text-align: center;
    margin-top: 10%;
}

.success-container {
    width: 34vw;
    border: 2px solid $color-modern-blue;
    border-radius: 10px;
    padding: 30px 20px 30px 50px;
    text-align: left;
    margin-bottom: 30px;

    h2 {
        margin-bottom: 0;
    }

    .card-img {
        height: 80px;
        width: 80px;
        border-radius: 50px;
    }

    .card-username {
        text-align: left;
    }

    .repo-info {
        margin-top: 30px;

        p {
            color: $color-modern-blue;
            font-size: 14px;
            margin-bottom: 5px;
            font-weight: 300;
        }

        h6 {
            font-size: 15px;
            font-weight: 400;
        }
}
}