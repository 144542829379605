@import '../variables/other';
@import '../variables/color';
@import '../fonts/fonts';


.btn-primary {
    background-color: $color-modern-blue;
    border-radius: 26px;
    padding: 0.8em 1em;
    font-weight: $body-bold-weight;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-transfer {
    width: 100%;
    font-size: 17px;
}

.icon-button {
    padding: 0.75rem 1rem;
}

.zoek-button {
    padding: 0.8rem;
}

.btn-dark:hover {
    background-color: #000;
}

.navigation-buttons {
    margin-bottom: 60px;


  
.btn-primary.dropdown-toggle:focus {
    box-shadow: none !important;
}

.btn-outline-primary {
    color: black;
    border-color: black;
    padding: .65rem 1.7rem;
}

.btn-outline-primary:hover {
    color: white;
    border-color: black;
    background-color: black;
}

}