@import '../fonts/fonts';
@import '../variables/color';

form {
    .datepicker {
        padding: 0.75rem !important;
    }
}

.modal-body {
    padding: 3rem;
    form {
        font-family: $font-family-base;

        input::placeholder {
            font-size: $small-font;
        }

        div:last-child {
            margin-top: 26px;
        }
    }
}

.form-control {
    padding: 0.75rem;
}

